import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import dayjs from "dayjs";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import { useFormik } from "formik";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import Constant from "../../../utills/Constant";
const AddScheduleEventManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editScheduleId, setEditScheduleId] = useState(null);
  const [selectedImagePrev, setSelectedImagePrev] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const handleOnChangeImages = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile(file);
          setSelectedImagePrev(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };
  useEffect(() => {
    if (location?.state?.scheduleEventId) {
      getScheduleEventDetails(location?.state?.scheduleEventId);
      setTypeCheck(location?.state?.type);
      setEditScheduleId(location?.state?.scheduleEventId);
    }
  }, [location?.state?.scheduleEventId]);
  const getScheduleEventDetails = async (id) => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(ApiList.GET_BY_ID, {
        params: {
          type: 6,
          id: id,
        },
      });
      if (response && response?.data?.s) {
        console.log("response", response);
        formik.setValues({
          eventName: response?.data?.r?.name,
          address: response?.data?.r?.location,
          startEvent: dayjs(response?.data?.r?.start_date),
          endEvent: dayjs(response?.data?.r?.end_date),
          thumb: response?.data?.r?.img,
        });
        if (response?.data?.r?.img) {
          setSelectedImagePrev(`${Constant.baseURL}${response?.data?.r?.img}`);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      eventName: "",
      address: "",
      startEvent: null,
      endEvent: null,
      thumb: null,
    },
    validationSchema: Yup.object().shape({
      eventName: Yup.string().required("Event name is required!"),
      address: Yup.string().required("Address is required!"),
      startEvent: Yup.string().required("Start event is required!"),
      endEvent: Yup.string().required("End event is required!"),
      thumb: Yup.mixed().required("This field is required."),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      try {
        const formData = new FormData();
        if (typeCheck === "Edit") {
          formData.append("id", editScheduleId);
        }
        formData.append("name", values.eventName);
        formData.append("location", values.address);
        formData.append(
          "start_date",
          moment(values.startEvent?.$d).utc().format("YYYY-MM-DD")
        );
        formData.append(
          "end_date",
          moment(values.endEvent?.$d).utc().format("YYYY-MM-DD")
        );
        if (selectedImageFile) {
          formData.append("img", selectedImageFile);
        }
        const apiUrl =
          typeCheck === "Edit" ? ApiList.EDIT_EVENT : ApiList.ADD_EVENT;
        const response = await AxiosInstance.post(apiUrl, formData);

        if (response && response?.data?.s === 1) {
          const message =
            typeCheck === "Edit"
              ? "Schedule Event Successfully Updated."
              : "Schedule Event Successfully Added.";
          toast.success(message);
          navigate(-1);
          setSelectedImageFile(null);
          setSelectedImagePrev(null);
        }
      } catch (error) {
        console.log("error", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
        resetForm();
      }
    },
  });
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "0",
            left: "0px",
            p: 1,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            zIndex: "999",
          }}
        >
          <IconButton
            style={{
              cursor: "pointer",
              fontWeight: "500",
              fontSize: "22px",
              textDecoration: "none",
              color: "black",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              border: "1px solid #0000003D",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "24px", xl: "30px" },
                fontWeight: "500",
              }}
            >
              {typeCheck ? "Update" : "Add"} ScheduleEvent
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box
          sx={{
            padding: "80px 15px 40px 15px",
            maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
            margin: "0 auto",
            width: "100%",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomThumbUpload
                  accept="image/*"
                  onDrop={(acceptedFiles, rejectedFiles) =>
                    handleOnChangeImages(acceptedFiles, rejectedFiles)
                  }
                  selectedFile={selectedImagePrev}
                  onRemove={(e) => {
                    e.stopPropagation();
                    setSelectedImagePrev(null);
                    setSelectedImageFile(null);
                    formik.setFieldValue("thumb", null);
                  }}
                  typeCheck={typeCheck}
                />
                {formik.touched.thumb && formik.errors.thumb ? (
                  <Typography
                    sx={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: { xs: "14px", sm: "14px" },
                      marginTop: "5px",
                    }}
                  >
                    {formik.errors.thumb}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Event Name"
                  type="text"
                  placeholder="Enter Event Name"
                  name="eventName"
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                  value={formik.values.eventName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.eventName && formik.errors.eventName}
                  onKeyUp={formik.handleChange}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Address"
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                  multiline
                  rows={3}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.address && formik.errors.address}
                  onKeyUp={formik.handleChange}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "500",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    mb: "10px",
                  }}
                >
                  Event Start
                </Typography>
                {isLoading ? (
                  <Skeleton height={60} />
                ) : (
                  <>
                    <CustomDateTimePicker
                      value={formik.values.startEvent}
                      inputFormat={"YYYY-MM-DD"}
                      onChange={(newValue) =>
                        formik.setFieldValue("startEvent", newValue)
                      }
                    />
                    {formik.touched.startEvent && formik.errors.startEvent ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: { xs: "14px", sm: "14px" },
                          marginTop: "5px",
                        }}
                      >
                        {formik.errors.startEvent}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "500",
                    textAlign: "left",
                    fontSize: { xs: "16px", sm: "18px" },
                    mb: "10px",
                  }}
                >
                  Event End
                </Typography>
                {isLoading ? (
                  <Skeleton height={60} />
                ) : (
                  <>
                    <CustomDateTimePicker
                      value={formik.values.endEvent}
                      onChange={(newValue) =>
                        formik.setFieldValue("endEvent", newValue)
                      }
                      inputFormat={"YYYY-MM-DD"}
                      error={formik.touched.endEvent && formik.errors.endEvent}
                    />
                    {formik.touched.endEvent && formik.errors.endEvent ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: "400",
                          fontSize: { xs: "14px", sm: "14px" },
                          marginTop: "5px",
                        }}
                      >
                        {formik.errors.endEvent}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    py: 3,
                    px: 4,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CustomLoaderButton
                    btnTitle={typeCheck ? "Update" : "Add"}
                    sx={{ width: "150px" }}
                    type="submit"
                    loading={isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddScheduleEventManagement;
