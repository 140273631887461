import axios from "axios";
const Development = false;
const AxiosInstance = axios.create({
  baseURL: Development
    ? "https://dev-api.healinginstitute.org/api"
    : "https://api.healinginstitute.org/api",
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const healingInsitute = JSON.parse(
      localStorage.getItem("HEALING_INSTITUTE")
    );
    config.headers.token = healingInsitute?.token;
    config.headers.apikey = healingInsitute?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("userInfo");
        // window.location.pathname = "/";
      } else {
        console.error("Response Error:", error.response);
      }
    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
