import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import deleteIcon from "../../../Assets/Images/delete.png";
import editIcon from "../../../Assets/Images/edit.png";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import Constant from "../../../utills/Constant";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomSearch from '../../../Components/CustomSearch/CustomSearch'
import useDebounce from "../../../Components/useDebounce/useDebounce";

const ResourcesManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [resourceList, setResourceList] = useState([]);

  const getResourcesList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(ApiList.GET_RESOURCES, {
        params: {
          count: page * pageSize,
          is_admin: 1
        }
      });
      if (response && response.data.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setResourceList(Rowdata);
        setCount(response.data.c);
      } else {
        setResourceList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getResourcesList();
  }, [page]);

  const columns = [
    { field: "rowid", headerName: "#", width: 50 },
    {
      field: "thumb",
      headerName: "Product Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={`${Constant.baseURL}${params?.row?.thumb}`}
            alt="delete"
            style={{ width: "30px", height: "30px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Product Name",
      flex: 1,
      renderCell: (params) => (params?.row?.name ? params?.row?.name : "--")
    },
    {
      field: "price",
      headerName: "Price of product",
      flex: 1,
      renderCell: (params) => (params?.row?.price ? params?.row?.price : "--")
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={editIcon}
            alt="edit"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() =>
              navigate("/admin/resouces-management/edit-marchendise", {
                state: {
                  resourceData: params?.row,
                  type: "Edit",
                },
              })
            }
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteResources(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  const handleDeleteResources = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this resource",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = { id, status: "0" };
        try {
          const res = await AxiosInstance.post(ApiList.DELETE_RESOURCES, params);
          if (res?.data?.s === 1) {
            toast.success("Resources Deleted Successfully");
            getResourcesList(); // Refresh the resource list after deletion
          }
        } catch (error) {
          toast.error("Failed to delete resource. Please try again.");
          console.log(error);
        }
      }
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: { xs: "22px", md: "32px" }, fontWeight: "500", mb: '16px' }}
            >
              Resource Management
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                sx={{
                  background: "#000E72",
                  color: "white",
                  "&:hover": { background: "#000E72", color: "white" },
                  textTransform: "none",
                  height: '54px'
                }}
                onClick={() => navigate("/admin/resouces-management/add-marchendise")}
              >
                + Add Merchandise
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{ padding: "24px 0px 0px 0px", borderTop: '1px solid #0000003D' }}
        >
          <TableContainer
            rows={resourceList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>
    </>
  );
};

export default ResourcesManagement;
