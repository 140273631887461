import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment/moment";
import deleteIcon from "../../../Assets/Images/delete.png";
import EditIcon from "../../../Assets/Images/edit.png";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";

const ScheduleEventManagement = () => {
  const pageSize = 10;
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleEventList, setScheduleEventList] = useState([]);

  const columns = [
    { field: "rowid", headerName: "#", width: 50 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (params?.row?.name ? params?.row?.name : "--")
    },
    {
      field: "location",
      headerName: "Address",
      flex: 1,
      renderCell: (params) => (params?.row?.location ? params?.row?.location : "--")
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1,
      renderCell: (params) => (
        params?.row?.start_date ? moment(params?.row?.start_date).local().format("DD/MM/YYYY") : "--"
      )
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 1,
      renderCell: (params) => (
        params?.row?.end_date ? moment(params?.row?.end_date).local().format("DD/MM/YYYY") : "--"
      )
    },
    {
      field: "is_active",
      headerName: "Activate/Deactivate",
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={params?.row?.is_active === 1}
                onChange={(e) => handleUpdateEventStatus(params?.row?.id, e.target.checked)}
              />
            }
          />
        </Box>
      )
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "15px" }}>
          <img
            src={EditIcon}
            alt="editIcon"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => navigate('/admin/edit-schedule-event', {
              state: {
                scheduleEventId: params?.row?.id,
                type: "Edit"
              }
            })}
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteSchedule(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  const getScheduleEventList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(ApiList.GET_EVENT, {
        params: {
          count: page * pageSize,
          is_admin: 1
        }
      });
      if (response && response?.data?.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setScheduleEventList(Rowdata);
        setCount(response.data.c);
      } else {
        setScheduleEventList([]);
      }
    } catch (error) {
      toast.error('Failed to fetch schedule events. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getScheduleEventList();
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleUpdateEventStatus = async (id, checked) => {
    try {
      const status = checked ? '1' : '0';
      const params = { id: id.toString(), is_active: status };
      const response = await AxiosInstance.post(ApiList.UPDATE_EVENT_STATUS, params);
      if (response && response?.data?.s === 1) {
        const message = checked ? 'Event Activated Successfully!' : 'Event Deactivated Successfully!';
        toast.success(message);
        getScheduleEventList();
      } else {
        toast.error('Failed to update event status.');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status. Please try again.');
      console.error("Error updating event status:", error);
    }
  };

  const handleDeleteSchedule = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = { id, status: "0" };
        try {
          const response = await AxiosInstance.post(ApiList.DELETE_EVENT, params);
          if (response?.data?.s === 1) {
            getScheduleEventList();
            toast.success("Event Deleted Successfully!");
          } else {
            toast.error("Failed to delete Event. Please try again.");
          }
        } catch (error) {
          toast.error("An error occurred while deleting the Event. Please try again.");
          console.error("Error deleting event:", error);
        }
      }
    });
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500", paddingBottom:'10px' }}>
              Schedule Event
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  background: "#000E72",
                  color: "white",
                  "&:hover": { background: "#000E72", color: "white" },
                  textTransform: "none",
                }}
                onClick={() => navigate("/admin/add-schedule-event")}
              >
                + Add Schedule Event
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ padding: "24px 0px 0px 0px", borderTop: '1px solid #0000003D' }}>
          <TableContainer
            rows={scheduleEventList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>
    </>
  );
};

export default ScheduleEventManagement;
