import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { adminLayoutRoute } from "../Routes/routes";
import { Middleware } from "../Config/Middleware";

const AdminLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("HEALING_INSTITUTE"));
    if (userCheck?.role !== 3 && userCheck?.role !== 2) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Routes>
        {adminLayoutRoute.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <Middleware
                id={route.id}
                component={route.component}
                sidebar={route.sidebar}
              />
            }
          />
        ))}
      </Routes>
    </Box>
  );
};

export default AdminLayout;
