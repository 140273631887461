import React, { useEffect, useState } from "react";
import { Box, Typography, Menu, MenuItem, Grid, IconButton } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AxiosInstance from '../../../Config/AxiosInstance';
import { ApiList } from "../../../Config/ApiList";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import useDebounce from "../../../Components/useDebounce/useDebounce";
const RegisterManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearch = useDebounce(searchUser, 500);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [registerUser, setRegisterUser] = useState([]);
  const [eventSchduleList, setEventSchduleList] = useState([]);
  const open = Boolean(anchorEl);
  const columns = [
    { field: "rowid", headerName: "#", width: 50, filterable: false },
    {
      field: "f_name", headerName: "First Name", flex: 1, minWidth: 200, renderCell: (params) => (
        params?.row?.f_name ? params?.row?.f_name : "_ _"
      ), filterable: true
    },
    {
      field: "l_name",
      headerName: "Last Name",
      flex: 1, minWidth: 200,
      renderCell: (params) => (
        params?.row?.l_name ? params?.row?.l_name : "_ _"
      ),
      filterable: true
    },
    {
      field: "country", headerName: "Country", flex: 1, renderCell: (params) => (
        params?.row?.country ? params?.row?.country : "_ _"
      ),
      filterable: true
      , flex: 1, minWidth: 200,
    },
    {
      field: "phno", headerName: "Mobile Number", flex: 1, renderCell: (params) => (
        params?.row?.phno ? `+${params?.row?.cc}${" "}${params?.row?.phno}` : "_ _"
      ), flex: 1, minWidth: 200,
    },
    {
      field: "name", headerName: "Event Name", flex: 1, renderCell: (params) => (
        params?.row?.name ? params?.row?.name : "_ _"
      ), flex: 1, minWidth: 200,
    },
    {
      field: "email", headerName: "Email", flex: 1, minWidth: 300, renderCell: (params) => (
        params?.row?.email ? params?.row?.email : "_ _"
      ),
    },
    {
      field: "what_condition", headerName: "What was the Condition?", flex: 1, minWidth: 200, renderCell: (params) => (
        params?.row?.what_condition ? params?.row?.what_condition : "_ _"
      ),
    },
    {
      field: "how_long",
      headerName: "How long have you been suffering from this condition?", flex: 1, minWidth: 200,
      renderCell: (params) => (
        params?.row?.how_long ? params?.row?.how_long : "_ _"
      )
    },
  ];
  const getRegisterList = async (filterId) => {
    setIsLoading(true);
    try {
      const params = {
        search: debouncedSearch,
        event_id: filterId,
        count: page * pageSize
      };
      const response = await AxiosInstance.get(ApiList.GET_REGISTER, { params });
      if (response && response?.data?.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));

        setRegisterUser(Rowdata);
        setIsLoading(false);
        setCount(response?.data?.c);
      } else {
        setRegisterUser([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRegisterList();
  }, [debouncedSearch, page])
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterEvent = (id) => {
    setAnchorEl(null);
    setPage(0);
    getRegisterList(id);
  };

  useEffect(() => {
    getEventList();
  }, [page]);
  const getEventList = async (id) => {
    try {
      const params = {
        is_admin: 1,
      };
      const response = await AxiosInstance.get(ApiList.GET_EVENT, {
        params: params
      });
      if (response && response?.data?.s) {
        setEventSchduleList(response?.data?.r);
      } else {
        setEventSchduleList([]);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500",paddingBottom:'10px' }}>
          Registered Members
        </Typography>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <CustomSearch
                placeholder="Search Here..."
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                onClear={() => setSearchUser("")}
              />
            </Grid>
            <Grid item xs={2} md={4}>
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  width: "50px",
                  height: '50px',
                  textTransform: "none",
                  color: "black",
                  fontSize: "16px",
                  background: "#2A2A2A14",
                  border: "1px solid #0000006B",
                  borderRadius: "8px",
                  "&:hover": { background: "#2A2A2A14", color: "black" },
                }}
              >
                <FilterAltIcon sx={{ fontSize: '32px' }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {eventSchduleList ? eventSchduleList?.map((elem, index) => {
                  return (
                    <MenuItem onClick={() => handleFilterEvent(elem?.id)} key={index}>{elem?.name}</MenuItem>
                  )
                }) : <Typography>No Data</Typography>}
              </Menu>
            </Grid>
          </Grid>
          <Box sx={{ padding: "24px 0px 0px 0px" }}>
            <TableContainer
              rows={registerUser}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              handlePageChange={handlePageChange}
              totalCount={count}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RegisterManagement;
