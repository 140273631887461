import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import logo from "../../../Assets/Images/logo.svg";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import { NavMenuList } from "../../../Layout/MenuItems";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("HEALING_INSTITUTE"));
    if (userCheck) {
      const index = userCheck?.previleges?.at(0) - 1;
      navigate(NavMenuList[index]?.path);
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const params = {
          email: values.email,
          password: values.password,
        };
        const response = await AxiosInstance.post(ApiList.LOGIN, params);
        if (response && response?.data?.s === 1) {
          if (response?.data?.r?.role === 2 || response?.data?.r?.role === 3) {
            localStorage.setItem(
              "HEALING_INSTITUTE",
              JSON.stringify(response?.data?.r)
            );
            const index = response?.data?.r?.previleges?.at(0) - 1;
            navigate(NavMenuList[index]?.path, { replace: true });
            toast.success("Login Successfully.");
            resetForm();
          } else {
            toast.error("You Are Not Authenticated");
          }
        } else {
          toast.error(response.data.m);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 2,
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            background: "#F0F0F0",
            borderRadius: "60px",
            py: 1,
            position: "relative",
            zIndex: "2",
          }}
        >
          <Box sx={{ height: "100%", width: "100%", p: 5 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                gap: "1.5rem",
                paddingTop: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTextField
                  label="Email Address"
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.email && formik.errors.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <CustomTextField
                  label="Password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.password && formik.errors.password}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography
                  onClick={() => navigate("/forgot-password")}
                  sx={{
                    color: "black",
                    textDecoration: "underline",
                    fontSize: { xs: "14px", sm: "18px" },
                    cursor: "pointer",
                    "&:hover": {
                      color: "#1E90B3",
                    },
                    textAlign: "right",
                  }}
                >
                  Forgot Password?
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                }}
              >
                <Box
                  sx={{
                    width: "20%",
                  }}
                >
                  <CustomLoaderButton
                    btnTitle="Submit"
                    loading={isLoading}
                    type="submit"
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
