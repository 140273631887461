import PathList from "./PathList";
import Dashboard from "../Assets/Images/Dashboard.png";
import User from "../Assets/Images/User.png";
import Register from "../Assets/Images/Register.png";
import Video from "../Assets/Images/Video.png";
import Gallery from "../Assets/Images/Gallery.png";
import Resource from "../Assets/Images/Resource.png";
import Tips from "../Assets/Images/Tips.png";
import Content from "../Assets/Images/Content.png";
import DashboardA from "../Assets/Images/DashboardA.png";
import UserA from "../Assets/Images/UserA.png";
import RegisterA from "../Assets/Images/RegisterA.png";
import VideoA from "../Assets/Images/VideoA.png";
import GalleryA from "../Assets/Images/GalleryA.png";
import ResourceA from "../Assets/Images/ResourceA.png";
import TipsA from "../Assets/Images/TipsA.png";
import ContentA from "../Assets/Images/ContentA.png";
import HealthA from "../Assets/Images/HealthA.png";
import Health from "../Assets/Images/Health.png";
import ScheduleA from "../Assets/Images/ScheduleA.png";
import Schedule from "../Assets/Images/Schedule.png";
import SubAdmin from "../Assets/Images/Subadmin.png";
import SubAdminA from "../Assets/Images/SubadminA.png";


export const NavMenuList = [
  {
    id:1,
    title: "Dashboard",
    path: PathList.Dashboard,
    icon: Dashboard,
    active: DashboardA,
  },
  {
    id:2,
    title: "User Management",
    path: PathList.UserManagement,
    icon: User,
    active: UserA,
  },
  {
    id:3,
    title: "Register User",
    path: PathList.RegisterUser,
    icon: Register,
    active: RegisterA,
  },
  {
    id:4,
    title: "Video Management",
    path: PathList.VideoManagement,
    icon: Video,
    active: VideoA,
  },
  {
    id:5,
    title: "Gallery",
    path: PathList.Gallery,
    icon: Gallery,
    active: GalleryA,
  },
  {
    id:6,
    title: "Resource Management",
    path: PathList.ResourcesManagement,
    icon: Resource,
    active: ResourceA,
  },
  {
    id:7,
    title: "Daily Divine Health Affirmations",
    path: PathList.Affirmative,
    icon: Tips,
    active: TipsA,
  },
  {
    id:8,
    title: "Health Tips",
    path: PathList.DAILYHEALTHTIP,
    icon: Health,
    active: HealthA,
  },
  {
    id:9,
    title: "Schedule Events",
    path: PathList.SCHEDULEEVENTMANAGMENT,
    icon: Schedule,
    active: ScheduleA,
  },
  {
    id:10,
    title: "Content Management",
    path: PathList.ContentManagement,
    icon: Content,
    active: ContentA,
  },
  {
    id:11,
    title: "Sub Admin",
    path: PathList.SubAdmin,
    icon: SubAdmin,
    active: SubAdminA,
  },
];
