import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { NavMenuList } from "./MenuItems";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PathList from "./PathList";
import Logo from "../Assets/Images/logo.svg";
import { Button } from "@mui/material";
import LogoutIcon from "../Assets/Images/Logout.png";
import Swal from "sweetalert2";

const drawerWidth = 280;

const Sidebar = (props) => {
  const { element } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const isAccessable = JSON.parse(localStorage.getItem("HEALING_INSTITUTE"))
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          title: "Logged Out",
          text: "You have been successfully logged out. Redirecting to login page...",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          navigate("/login");
        });
      }
    });
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const drawer = (
    <Box
      sx={{
        bgcolor: "white",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: `calc(100vh - 95px )`, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: "2rem",
            height: "180px",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            srcSet=""
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <List sx={{ height: `calc(100vh - 250px )`, overflow: "auto" }}>
          {NavMenuList &&
            NavMenuList?.map(({ title, path, icon, active,id }, i) => {
             const data =  isAccessable?.previleges?.includes(id) 
            

              return (
                data &&
                <ListItem key={i} disablePadding sx={{ m: "0.5rem 0" }}>
                  <Link
                    style={{
                      color: location.pathname.includes(path)
                        ? "black"
                        : "#00000099",
                      textDecoration: "none",
                      width: "100%",
                    }}
                    to={path}
                  >
                    <ListItemButton
                      sx={{
                        background: location.pathname.includes(path)
                          ? "#F0F0F0"
                          : "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "44px",
                        }}
                      >
                        {location.pathname.includes(path) ? (
                          <img
                            src={active}
                            style={{
                              boxSizing: "border-box",
                              height: "25px",
                              width: "25px",
                            }}
                            alt=""
                            srcSet=""
                          />
                        ) : (
                          <img
                            src={icon}
                            style={{
                              boxSizing: "border-box",
                              height: "25px",
                              width: "25px",
                            }}
                            alt=""
                            srcSet=""
                          />
                        )}{" "}
                      </ListItemIcon>
                      <ListItemText
                        primary={title}
                        sx={{
                          fontSize: "16px",
                          color: location.pathname.includes(path)
                            ? "black"
                            : "#00000099",
                          ".MuiTypography-root": {
                            fontWeight: "500",
                          },
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              );
            })}
        </List>
      </Box>
      {/* Logout Button */}
      <Button
        type="button"
        sx={{
          width: "100%",
          background: "transparent",
          padding: "8px 12px",
          fontWeight: "500",
          fontSize: "18px",
          color: "#737373",
          textTransform: "capitalize",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          borderRadius: "0px",
          "&:hover": {
            background: "transparent",
            color: "#737373",
          },
        }}
        onClick={() => handleLogout()}
      >
        <img src={LogoutIcon} alt="" /> Logout
      </Button>

      <Divider />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          //   ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontWeight={"600"}
            color={"#eee"}
            onClick={handleDrawerToggle}
          >
            {location.pathname === PathList.Dashboard
              ? "Dashboard"
              : location.pathname === PathList.UserManagement
              ? "User Management"
              : location.pathname === PathList.RegisterUser
              ? "Register User"
              : location.pathname === PathList.VideoManagement
              ? "Video Management"
              : location.pathname === PathList.Gallery
              ? "Gallery"
              : location.pathname === PathList.Affirmative
              ? "Affirmative & Tips"
              : location.pathname === PathList.ContentManagement &&
                "Content Management"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ display: { sm: "none" } }}>
          <Toolbar />
        </Box>
        <Box>{element}</Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
