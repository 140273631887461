import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import UserAccess from "../../../Assets/Images/SubAdminAccess.png";
import ResetAccess from "../../../Assets/Images/Reset.png";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import EDIT_ICON from "../../../Assets/Images/edit.png";
import DELETE_ICON from "../../../Assets/Images/delete.png";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import Skeleton from "react-loading-skeleton";
import { CheckBox } from "@mui/icons-material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const SubAdmin = () => {
  const pageSize = 10;
  const [getSubAdmin, setGetSubAdmin] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [busy, setBusy] = useState(false);
  const [selectedRights, setSelectedRights] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeCheck, setTypeCheck] = useState(null);
  const [singleDetails, setSingleDetails] = useState({});
  const handleOpenModal = (data) => {
    setOpenModal(true);
    setTypeCheck(null);
    setSingleDetails({});
  };
  const handleEdit = (data) => {
    setOpenModal(true);
    setTypeCheck("Edit");
    setSingleDetails(data);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setTypeCheck(null);
    setSingleDetails({});
  };
  const [allAccount, setAllAccount] = useState([]);
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Sub-Admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          is_delete: 1,
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.EDIT_SUBADMIN,
            params
          );
          if (response?.data?.s === 1) {
            getSubAdminList(); 
            toast.success("Sub-Admin Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete Sub-Admin. Please try again.");
        }
      }
    });
  };
  const getSubAdminList = async () => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_SUBADMIN_LIST, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        },
      });
      if (res && res?.data?.s === 1) {
        const Rowdata = res?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setGetSubAdmin(Rowdata);
        setCount(res?.data?.c || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSubAdminList();
  }, [page]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: typeCheck === "Edit" ? singleDetails?.user_name :  "",
      email: typeCheck === "Edit" ? singleDetails?.email :  "",
      password:"",
      Cpassword: "",
      rights: typeCheck === "Edit" ? singleDetails?.previleges : [],
    },
    
    validationSchema:  Yup.object().shape({
      username:  Yup.string().required("Please enter username"),
      email: Yup.string()
        .required("Please enter email address")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please Provide Valid Email"
        ),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
        ),
      Cpassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords don't match"),
      // rights: Yup.array().min(1, "Please select at least one right"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("email", values?.email);
        formData.append("password", values?.password);
        const rights = values?.rights.map((elem, index) => elem?.id);
        formData.append("previleges", rights);
        formData.append("user_name", values?.username);
        const res = await AxiosInstance.post(
          ApiList.ADD_SUBADMIN,
          formData
        );

        if (res && res?.data?.s === 1) {
          handleCloseModal();
          getSubAdminList();
          toast.success("Sub-Admin added sucessfully");
          resetForm();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const columns = [
    {
      field: "rowid",
      headerName: "#",
      width: 50,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (params?.row?.email ? params?.row?.email : "--"),
    },
    {
      field: "user_name",
      headerName: "User Name",
      flex: 1,
      renderCell: (params) =>
        params?.row?.user_name ? params?.row?.user_name : "--",
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <IconButton onClick={() => handleEdit(params?.row)}>
            <img
              src={EDIT_ICON}
              alt="view"
              style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleDelete(params?.row?.id)}>
            <img
              src={DELETE_ICON}
              alt="reset"
              style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPrivilages = async () => {
    try {
      const res = await AxiosInstance.get(ApiList.GET_ALL_PREVELIGES, {
        params: {
          user_id: 0,
        },
      });
      if (res && res?.data?.s === 1) {
        setAllAccount(res?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPrivilages();
  }, []);
  const handleCheckBoxChange = (elem) => {
    const { name, id } = elem;
    if (selectedRights.some((right) => right.id === id)) {
      setSelectedRights(selectedRights.filter((right) => right.id !== id));
      formik.setFieldValue(
        "rights",
        formik.values.rights.filter((right) => right.id !== id)
      );
    } else {
      setSelectedRights([...selectedRights, { name, id }]);
      formik.setFieldValue("rights", [...formik.values.rights, { name, id }]);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: "15px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "28px" },
                fontWeight: "500",
                paddingBottom: "10px",
              }}
            >
              Sub Admin
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  background: "#000E72",
                  color: "white",
                  "&:hover": { background: "#000E72", color: "white" },
                  textTransform: "none",
                }}
                onClick={handleOpenModal} // Open modal on click
              >
                + Add Sub-Admin
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            padding: "24px 0px 24px 0px",
            borderTop: "1px solid #0000003D",
          }}
        >
          <TableContainer
            rows={getSubAdmin}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>

      {/* Modal for Adding Sub-Admin */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "40px",
            height: "500px",
            overflow: "scroll",
          }}
          className="addsubadmin"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              Add Sub-Admin
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Form Fields */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: "20px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <CustomTextField
                    label="User Name"
                    type="text"
                    placeholder="Enter User Name"
                    name="username"
                    value={formik.values.username}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                  
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={formik.touched.username && formik.errors.username}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                  <CustomTextField
                    label="Email"
                    type="text"
                    placeholder="Enter Email"
                    name="email"
                    disabled={typeCheck === "Edit"}
                    value={formik.values.email}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={formik.touched.email && formik.errors.email}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                  <CustomTextField
                    label="Password"
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    value={formik.values.password}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={formik.touched.password && formik.errors.password}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                        display: typeCheck === "Edit" ? "none" : ""
                      },
                    
                    }}
                    style={{
                      display: typeCheck === "Edit" ? "none" : ""
                    }}
                  />
                  <CustomTextField
                    label="Confirm Password"
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="Cpassword"
                    value={formik.values.Cpassword}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={formik.touched.Cpassword && formik.errors.Cpassword}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                        display: typeCheck === "Edit" ? "none" : ""
                      },
                    
                    }}
                    style={{
                      display: typeCheck === "Edit" ? "none" : ""
                    }}
                  />
                </Box>

                {/* Submit Button */}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CustomLoaderButton
                    btnTitle={typeCheck === "Edit" ? "Update Sub-Admin" : "Add Sub-Admin"}
                    variant="contained"
                    type="submit"
                    sx={{
                      mt: 4,
                      backgroundColor: "#000E72",
                      color: "white",
                      px: 2,
                      width: "50%",
                      fontSize: "14px",
                    }}
                  />
                </Box>
              </form>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {formik.errors.rights ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {formik.errors.rights}
                </Typography>
              ) : null}
              <Grid container spacing={3}>
                {allAccount &&
                  allAccount?.map((elem, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={index}
                        sx={{ display: "flex", justifyContent: "start" }}
                      >
                        {isLoading || busy ? (
                          <Skeleton height={48} />
                        ) : (
                          <>
                            {/* Wrap Checkbox with FormControlLabel */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ color: "black" }}
                                  checked={selectedRights.some(
                                    (right) => right.id === elem.id
                                  )}
                                  onChange={() => handleCheckBoxChange(elem)} // Add onChange handler
                                />
                              }
                              label={elem.name} // Display label
                            />
                          </>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
