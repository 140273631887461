import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PathList from "../Layout/PathList";
import { Box } from "@mui/material";
import React from "react";
import Sidebar from "../Layout/Sidebar";

export const Middleware = ({ id, component, sidebar }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isLogin = JSON.parse(localStorage.getItem("HEALING_INSTITUTE"));
  const hasAccess = isLogin?.previleges?.includes(Math.floor(id));

  useEffect(() => {
    setLoading(true);
    if (!isLogin) {
      navigate(PathList.Login); 
    }
    setLoading(false);
  }, [isLogin, navigate]);
  useEffect(() => {
    if (isLogin && !hasAccess) {
      navigate(PathList.Login); 
    }
  }, [hasAccess, navigate, isLogin]);

  const clonedComponent = React.cloneElement(component, { id });

  return (
    !loading &&
    isLogin && hasAccess && (
      <Box sx={{ display: "flex", height: "100vh",width:"100%" }}>
        {sidebar && <Sidebar />}
        <Box
          sx={{
            width: sidebar ? "calc(100% - 280px)" : "100%",
            height: "100vh",
            overflow: "auto",
          }}
        >
          {clonedComponent}
        </Box>
      </Box>
    )
  );
};
